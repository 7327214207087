/** @jsx jsx */
import React from "react";
import { HowData } from "@/data";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import Img1 from "@/images/medalhistas/viagem.jpg"
import Img2 from "@/images/medalhistas/premiacao.png"

import {
how
} from "../assets/styles/How.styles";
import {
    secTitle,
    commonSection,
  } from "../assets/styles/layout.styles";

  const How = () => {
    const { image } = HowData;
    return (
      <section id="sobre" css={[commonSection,how]}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>            
                <div>
                  {/* <h2 css={secTitle}>Medalhistas</h2> */}
                  <p>Um grupo de engenheiros do ITA, 554 professores de Matemática mobilizados, 10 medalhistas de ouro valorizados pelo empenho em ensinar matemática e transformar o País pela educação, com direito a reconhecimento do MEC e uma viagem de intercâmbio a Xangai. Assim foi a primeira edição da Olimpíada de Professores de Matemática do Ensino Médio (OPMbr).</p>
                  <img src={Img1} />
                  <p>A iniciativa surgiu como resultado do desejo de um grupo de engenheiros da turma de 89 do Instituto Tecnológico de Aeronáutica (ITA) de contribuir para melhorar o ensino de matemática no País, por meio da valorização de boas práticas docentes.</p>
                  <p>554 professores de matemática do ensino médio, de norte a sul do Brasil, se inscreveram e 67 foram premiados com medalhas de ouro (10), prata (9) e bronze (48).</p>
                  <img src={Img2} />
                  <p>Os 10 medalhistas de ouro, vencedores da OPMbr, receberam homenagens e puderam compartilhar um pouco de suas experiências em sala de aula em um evento promovido pelo Ministério da Educação (MEC) em maio de 2024.</p>
                  <p>Depois, em setembro de 2024, os vencedores receberam o prêmio pela conquista: uma viagem de intercâmbio acadêmico-cultural a Xangai, na China, vivendo a experiência de conhecer de perto e aprender com professores do país, que tem um dos melhores resultados no ranking de Matemática do PISA, programa internacional de avaliação de estudantes coordenado pela OCDE (Organização para Cooperação e Desenvolvimento Econômico).</p>
                  <p>Após o intercâmbio, os vencedores da OPMbr irão compartilhar a experiência adquirida por meio de apresentações, cursos e workshops, uma etapa fundamental do projeto, para a disseminação das boas práticas de ensino de matemática com outros professores das escolas do seu estado e do Brasil.</p>
                </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  };
  
  export default How;