/** @jsx jsx */
import React from "react";
import { MedalhistasHeaderData } from "@/data";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { jsx } from "@emotion/react";

import { HeaderMedalhistas, pr79, abImg } from "../assets/styles/QuemSomos.styles";
import {
  secTitle,
  secDesk,
  commonSection,
  commonBtn,
} from "../assets/styles/layout.styles";

const QuemSomos = () => {
  const { sectionContent } = MedalhistasHeaderData;
  return (
    <section css={[commonSection, HeaderMedalhistas]}>
      <Container>
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col
            css={pr79}
            lg={6}
            md={12}
            sm={12}
            data-aos-offset={"0"}
            data-aos={"fade"}
            data-aos-duration={"1000"}
            data-aos-delay={"300"}
            data-aos-once={true}
          >
            {/* <img src={sectionContent.logo} alt="logo"/> */}
            <figure>
              <div className="whiteOverlay"></div>
              <img
                src={sectionContent.imgMobile}
              />
            </figure>
            <h1 css={[secTitle]} className="professores_mobile">
              {sectionContent.titleMobile}
            </h1>
            <h1 css={[secTitle]}>{sectionContent.title}</h1>

            {/* <a css={[commonBtn,redBg]} href={button.url}> 
              <span>{button.label}</span>
            </a> */}
          </Col>
          {/* <Col lg={6} md={6} sm={12}>
            <div css={abImg}>
              <img src={sectionContent.img1} alt="" className="prof1" />
              <img src={sectionContent.img2} alt="" className="prof2" />
            </div>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
};

export default QuemSomos;
